const DISTANCE = 150
const DURATION = 150
const map = new WeakMap()
const ob = new IntersectionObserver(entries => {
	for (const entry of entries) {
		if (entry.isIntersecting) {
			// 元素和视口相交
			const animation = map.get(entry.target)
			if (animation) {
				animation.play()
				ob.unobserve(entry.target)
			}
		}
	}
})
export default {
	mounted(el) {
		if (!isBelowViewport(el)) {
			return
		}
		const animation = el.animate([{
				transform: `translateY(${DISTANCE}px)`,
				opacity: 0.5
			},
			{
				transform: `translateY(0)`,
				opacity: 1
			},
		], {
			duration: DURATION,
			easing: 'ease-in-out',
			fill: 'forwards'
		})
		animation.pause()
		ob.observe(el)
		map.set(el, animation)
	},
	unmounted(el) {
		ob.unobserve(el)
	}
}