<template>
	<div class="xw-table" ref="tableBox">
		<el-tables :header-cell-style="{background:'#FAFAFA'}" :data="data" v-bind="$attrs" style="width: 100%"
			v-if="tableHeight" :height="tableHeight + 'px'">
			<el-table-column v-if="showIndex" type="index" width="80" label="序号" :header-align="align" :align="align" />
			<el-table-column v-bind="item" v-for="(item,index) in column" :key="index"
				:show-overflow-tooltip="showOverflowTooltip" :header-align="item.align || align" :align="item.align||align" :width="item.width || 'auto'" :min-width="item.minWidth || null">
				<template slot-scope="scope">
					<template v-if="formatter.includes(item.prop)">
						<slot :name='item.prop' v-bind="scope" />
					</template>
					<template v-else>{{tableFilter( scope.row, scope.column,scope.row[item.prop])}}</template>
				</template>
			</el-table-column>
			<el-table-column :width="operateWidth" label="操作" :header-align="align" :align="align"
				v-if="$scopedSlots.default && operateWidth" :fixed="fixed">
				<template slot-scope="scope">
					<slot v-bind="scope" />
				</template>
			</el-table-column>
		</el-tables>
		<div class="xw-pagination" v-if="page">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:page-sizes="sizes" :page-size="limit" :total="total" layout="total, sizes, prev, pager, next, jumper" />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			operateWidth: {
				type: [Number, String],
				default: 150
			},
			tableFilter: {
				type: Function,
				default (row, column, value) {
					if (value === '' || value === null || value === undefined) {
						return '--'
					}
					return value
				}
			},
			data: {
				type: Array,
				default () {
					return []
				}
			},
			formatter: {
				type: Array,
				default () {
					return []
				}
			},
			showIndex: {
				type: Boolean,
				default: false
			},
			column: {
				type: Array,
				default () {
					return []
				}
			},
			page: {
				type: [Number, String],
				default: 0
			},
			sizes: {
				type: Array,
				default () {
					return [20, 50, 100]
				}
			},
			limit: {
				type: [Number, String],
				default: 20
			},
			total: {
				type: [Number, String],
				default: 0
			},
			showOverflowTooltip: {
				type: Boolean,
				default: false
			},
			fixed: {
				type: [String, Boolean],
				default: false
			},
			align: {
				type: String,
				default: 'center'
			}
		},
		data() {
			return {
				tableHeight: 0,
			}
		},
		// computed: {
		// 	tableHeight() {
		// 		if (this.$refs.tableBox) {
		// 			return this.$refs.tableBox.clientHeight - (this.page ? 40 : 0);
		// 		}
		// 		return 0
		// 	}
		// },
		watch: {
			data() {
				this.initTableHeight()
			}
		},
		created() {
			// mounted() {
			//减去翻页的高度，因为容器包含了表格和翻页
			this.initTableHeight()
		},
		methods: {
			initTableHeight() {
				this.$nextTick(() => {
					const tableHeight = this.$refs.tableBox.clientHeight - (this.page ? 40 : 0);
					if (tableHeight > this.tableHeight) {
						this.tableHeight = tableHeight
					}
				})
			},
			handleSizeChange(val) {
				this.$emit('page-size', val)
			},
			handleCurrentChange(val) {
				this.$emit('page-change', val)
			}
		}
	}
</script>

<style lang="scss">
	.xw-pagination {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		height: 40px;
	}
</style>