import Vue from 'vue'
import dialogDrag from './dialog-drag' //弹窗拖拽属性
import inputNumber from './input-number' //数值型且为正整数
import noMoreClick from './no-more-click' //防重复点击
import sticky from './sticky' //吸顶
import waves from './waves' //吸顶
import slideIn from './slide-in' //元素平滑上升
import {
	XwMessage
} from './message' //重写弹窗只显示一个


Vue.prototype.$message = new XwMessage()
// v-dialogDrag: 弹窗拖拽属性
Vue.directive('dialogDrag', dialogDrag)
Vue.directive('input', inputNumber)
Vue.directive('noMoreClick', noMoreClick)
Vue.directive('sticky', sticky)
Vue.directive('waves', waves)
Vue.directive('slideIn', slideIn)