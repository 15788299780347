/**
 * Created by sxw at 2023/9/11
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'
import router from '@/router'

export const upload = http.adornUrl("/sys/oss/upload")

export function login(data) {
	return http({
		url: http.adornUrl('/sys/login'),
		method: 'post',
		isJson: 1,
		data: http.adornData(data)
	})
}

export function getDate() {
	return http({
		url: '/',
		method: 'OPTIONS',
	})
}

export function getNav() {
	return new Promise((res, rej) => {
		http({
			url: http.adornUrl('/sys/menu/nav'),
			method: 'get',
			params: http.adornParams()
		}).then(({
			data
		}) => {
			if (data && data.result) {
				res(data.data)
			} else {
				// clearLoginInfo()
				rej()
				// router.push({
				// 	name: 'login'
				// })
				console.log('暂无权限')
			}
		}).catch(_ => {
			rej()
			console.log('暂无权限', _)
		})
	})
}