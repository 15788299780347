// 提交以后禁用按钮一段时间，防止重复提交
export default {
	inserted(el, binding) {
		el.addEventListener('click', e => {
			el.classList.add('is-disabled')
			// el.style.cssText = ';cursor:pointer;'
			el.disabled = true
			setTimeout(() => {
				el.disabled = false
				el.classList.remove('is-disabled')
			}, 1000)
		})
	}
}