/**
 * Created by sxw at 2023/10/6
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/park/tpark/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl('/park/tpark/info/' + id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/park/tpark/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function parkConfigInfo(data) {
	return http({
		url: http.adornUrl('/park/tpark/getParkConfig'),
		method: "get",
		params: http.adornParams(data),
	})
}

export function parkConfig(data) {
	return http({
		url: http.adornUrl(`/park/tpark/parkConfig`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	return http({
		url: http.adornUrl("/park/tpark/delete"),
		method: "post",
		data: http.adornData(data, false),
	})
}

export function spaceTypeList(data) {
	return http({
		url: http.adornUrl("/park/spaceType/listAll"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function getCarCount(data) {
	return http({
		url: http.adornUrl("/park/appservice/getCarCount"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function monthIncome(data) {
	return http({
		url: http.adornUrl("/park/appservice/monthIncome"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function monthTraffic(data) {
	return http({
		url: http.adornUrl("/park/dataScreen/monthTraffic"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function hasOrderFree(data) {
	return http({
		url: http.adornUrl("/order/orderInfo/oderInfoToFree.jk"),
		method: 'post',
		data
	})
}