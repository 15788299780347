/**
 * Created by sxw at 2023/10/6
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/sys/user/list"),
		method: "get",
		params: http.adornParams(data),
	})
}


export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/sys/user/${!data.user.userId?'saveandrole':'updateandrole'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	return http({
		url: http.adornUrl("/sys/user/delete"),
		method: "post",
		data: http.adornData(data, false),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl(`/sys/user/info${id?'/'+id:''}`),
		method: "get",
	})
}
export function getUserData(id) {
	return http({
		url: http.adornUrl(`/sys/data/getUserData/` + id),
		method: "get",
	})
}
export function saveUserData(data) {
	return http({
		url: http.adornUrl(`/sys/data/save`),
		method: "post",
		data
	})
}