<template>
	<transition name="dialog-fade" @after-enter="afterEnter" @after-leave="afterLeave">
		<div v-show="visible" class="el-dialog__wrapper" @click.self="handleWrapperClick">
			<div role="dialog" :key="key" aria-modal="true" :aria-label="title || 'dialog'"
				:class="['el-dialog', { 'is-fullscreen': fullscreen, 'el-dialog--center': center }, customClass]" ref="dialog"
				:style="style">
				<div class="el-dialog__header">
					<slot name="title">
						<span class="el-dialog__title">{{ title }}</span>
					</slot>
					<button class="el-dialog__headerbtn">
						<i class="el-icon-full-screen" @click="fullscreen=!fullscreen"></i>
						<!-- 						<i class="el-icon-full-screen" v-if="showfullScreen" @click="handleFullScreen"></i> -->
						<i class="el-icon-close" v-if="showClose" @click="handleClose"></i>
					</button>
				</div>
				<div class="el-dialog__body" v-if="rendered">
					<slot></slot>
				</div>
				<div class="el-dialog__footer" v-if="$slots.footer">
					<slot name="footer"></slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import Popup from 'element-ui/src/utils/popup';
	import Migrating from 'element-ui/src/mixins/migrating';
	import emitter from 'element-ui/src/mixins/emitter';

	export default {
		name: 'ElDialog',

		mixins: [Popup, emitter, Migrating],

		props: {
			title: {
				type: String,
				default: ''
			},

			modal: {
				type: Boolean,
				default: true
			},

			modalAppendToBody: {
				type: Boolean,
				default: true
			},

			appendToBody: {
				type: Boolean,
				default: false
			},

			lockScroll: {
				type: Boolean,
				default: true
			},

			closeOnClickModal: {
				type: Boolean,
				default: false
			},

			closeOnPressEscape: {
				type: Boolean,
				default: true
			},

			showClose: {
				type: Boolean,
				default: true
			},
			showfullScreen: {
				type: Boolean,
				default: true
			},

			width: String,

			fullscreen: Boolean,

			customClass: {
				type: String,
				default: ''
			},

			top: {
				type: String,
				default: '15vh'
			},
			beforeClose: Function,
			center: {
				type: Boolean,
				default: false
			},

			destroyOnClose: Boolean
		},

		data() {
			return {
				closed: false,
				key: 0
			};
		},

		watch: {
			visible(val) {
				if (val) {
					this.closed = false;
					this.$emit('open');
					this.$el.addEventListener('scroll', this.updatePopper);
					this.$nextTick(() => {
						this.$refs.dialog.scrollTop = 0;
					});
					if (this.appendToBody) {
						document.body.appendChild(this.$el);
					}
				} else {
					this.$el.removeEventListener('scroll', this.updatePopper);
					if (!this.closed) this.$emit('close');
					if (this.destroyOnClose) {
						this.$nextTick(() => {
							this.key++;
						});
					}
				}
			}
		},

		computed: {
			style() {
				let style = {};
				if (!this.fullscreen) {
					style.marginTop = this.top;
					if (this.width) {
						style.width = this.width;
					}
				}
				return style;
			}
		},

		methods: {
			handleMinus() {

			},
			handleFullScreen() {
				if (!!!document.fullscreenElement) {
					this.launchFullscreen()
				} else {
					this.exitFullscreen()
				}
			},
			// 进入全屏
			launchFullscreen() {
				const element = document.getElementsByClassName('el-dialog')[0]
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.mozRequestFullScreen) {
					//不同浏览器之间需要添加前缀
					element.mozRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					element.msRequestFullscreen();
				} else if (element.webkitRequestFullscreen) {
					element.webkitRequestFullScreen();
				}
			},
			// 退出全屏
			exitFullscreen() {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				}
			},
			getMigratingConfig() {
				return {
					props: {
						'size': 'size is removed.'
					}
				};
			},
			handleWrapperClick() {
				if (!this.closeOnClickModal) return;
				this.handleClose();
			},
			handleClose() {
				if (typeof this.beforeClose === 'function') {
					this.beforeClose(this.hide);
				} else {
					this.hide();
				}
			},
			hide(cancel) {
				if (cancel !== false) {
					this.$emit('update:visible', false);
					this.$emit('close');
					if (!!document.fullscreenElement) {
						this.exitFullscreen()
					}
					this.closed = true;
				}
			},
			updatePopper() {
				this.broadcast('ElSelectDropdown', 'updatePopper');
				this.broadcast('ElDropdownMenu', 'updatePopper');
			},
			afterEnter() {
				this.$emit('opened');
			},
			afterLeave() {
				this.$emit('closed');
			}
		},

		mounted() {
			if (this.visible) {
				this.rendered = true;
				this.open();
				if (this.appendToBody) {
					document.body.appendChild(this.$el);
				}
			}
		},

		destroyed() {
			// if appendToBody is true, remove DOM node after destroy
			if (this.appendToBody && this.$el && this.$el.parentNode) {
				this.$el.parentNode.removeChild(this.$el);
			}
		}
	};
</script>