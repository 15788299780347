<template>
	<el-image-viewer v-if="dialogVisible" :on-close="closeViewer" :url-list="imageList" >
	</el-image-viewer>
</template>

<script>
	import ElImageViewer from "element-ui/packages/image/src/image-viewer";
	export default {
		props: {
			imageList: {
				type: Array,
				default: () => []
			},
			dialogVisible: {
				type: Boolean,
				default: false
			}
		},
		components: {
			ElImageViewer
		},
		methods: {
			closeViewer() {
				this.$emit('on-close')
			},
		}
	}
</script>

<style>
	.el-image-viewer__wrapper{
		z-index: 99999 !important;
	}
</style>