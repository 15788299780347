<template>
	<transition name="fade">
		<router-view />
	</transition>
</template>

<script>
	export default {
		watch: {
			$route: {
				immediate: true,
				handler(to, from) {
					if (from && from.path && from.path == '/login') {
						window.location.reload();
					}
					const {
						meta,
						params,
						query,
						name,
					} = to;
					const {
						permissions = []
					} = meta
					let title = '';
					if (meta.title) {
						title = meta.title;
					} else if (params && params.title) {
						title = params.title;
					} else if (query && query.title) {
						title = query.title;
					}
					// permissions
					this.$store.commit("common/updatePermissions", permissions);
					document.title = title;
				},
				deep: true
			}
		},
		mounted() {
			window.addEventListener(
				'hashchange',
				() => {
					let currentPath = window.location.hash.slice(1);
					if (this.$route.path !== currentPath) {
						this.$router.push(currentPath);
					}
				},
				false
			);
		}
	};
</script>