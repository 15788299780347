/**
 * Created by sxw at 2023/10/6
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'
import {
	objectToQueryString
} from '@/utils'
export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/park/testate/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl('/park/testate/info/' + id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/park/testate/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	return http({
		url: http.adornUrl("/park/testate/delete"),
		method: "post",
		data: http.adornData(data, false),
	})
}

export function streamList(data) {
	delete data.total
	return http({
		url: http.adornUrl("/order/ttransactionrecord/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function exprotExcel(data) {
	//下载文件 　　js模拟点击a标签进行下载
	delete data.total
	var link = document.createElement('a')
	link.href = http.adornUrl(
		'/order/ttransactionrecord/estateExportExcel', 1) + '&' + objectToQueryString(data)
	link.target = '_blank'
	link.click();
	// http({
	// 	url: http.adornUrl("/order/ttransactionrecord/estateExportExcel"),
	// 	method: "get",
	// 	params: http.adornParams(data),
	// }).then(res => {
	// 	http.down({
	// 		data: res.data,
	// 		name: res.headers["content-disposition"]
	// 			.split(";")[1]
	// 			.split("=")[1]
	// 	})
	// })
}

// 分账详情
export function billDetailsList(data) {
	delete data.total
	return http({
		url: http.adornUrl("/pay/tspiltdetails/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function billDetailsDown(data) {
	//下载文件 　　js模拟点击a标签进行下载
	delete data.total
	console.log(http.adornParams(data))
	var link = document.createElement('a')
	link.href = http.adornUrl(
		`/order/tspiltdetails/splitExportExcel`, 1) + '&' + objectToQueryString(data)
	link.click()
}

// 支付宝流水
export function aliDecibelList(data) {
	delete data.total
	return http({
		url: http.adornUrl("/pay/alitransfer/list"),
		method: "get",
		params: http.adornParams(data),
	})
}