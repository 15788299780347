<template>
	<el-button class="xw-file-update" @click="click">
		上传
		<input type="file" :accept="accept" @change="change" ref='upload'>
	</el-button>
	</div>
</template>

<script>
	export default {
		props: {
			action: String,
			accept: '',
			data: {}
		},
		methods: {
			click() {
				this.$refs.upload.click()
			},
			change() {
				this.upload()
			},
			async upload() {
				if (this.action) {
					if (this.$refs.upload.files.length) {
						const formData = new FormData();
						formData.append("file", this.$refs.upload.files[0]);
						formData.append("type", "file");
						for (const key in this.data) {
							formData.append(key, this.data[key]);
						}
						const resp = await fetch(this.action, {
							method: "POST",
							body: formData
						})
						const res = await resp.json();
						this.$emit('onUpload', res)
						if (res.result && res.data) {
							this.$emit('input', res.data)
						} else {
							this.$message.error(res.data.msg)
						}
						this.$refs.upload.value = ''
						console.log(res)
					}
				}
			},
		},
	}
</script>

<style lang="scss">
	.xw-file-update {
		position: relative;
		cursor: pointer;
		background: #fff !important;

		* {
			cursor: pointer;
		}

		input {
			display: none;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
	}
</style>