import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {
	clearLoginInfo,
	getLoginInfo
} from '@/utils'
import {
	getNav
} from '@/api/admin'
NProgress.configure({
	easing: 'ease', // 动画方式
	speed: 500, // 递增进度条的速度
	showSpinner: true, // 是否显示加载ico
	trickleSpeed: 200, // 自动递增间隔
	minimum: 0.3 // 初始化时的最小百分比
})

// push
let originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location, resolve, reject) {
	if (resolve && reject) {
		originPush.call(this, location, resolve, reject);
	} else {
		originPush.call(this, location, () => {}, () => {});
	}
}
// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const globalRoutes = [{
		path: '/login',
		component: () => import('@/views/common/login.vue'),
		name: 'login',
		meta: {
			title: '登录',
		}
	},
	{
		path: "/sentry",
		component: () => import('@/views/sentry/index.vue'),
		name: 'sentry',
		meta: {
			title: '岗亭端',
		}
	},
	{
		path: "/404",
		component: () => import('@/views/common/404.vue'),
		name: '404',
		meta: {
			title: '404',
		}
	}
]

const layoutRoutes = [{
	path: '/home',
	component: () => import('@/views/common/home.vue'),
	name: 'home',
	meta: {
		title: '首页',
		fixed: true,
		parentId: 'home',
	}
}]

const routes = [{
	path: '/',
	component: layout,
	name: 'layout',
	redirect: {
		name: 'home'
	},
	meta: {
		title: '主入口整体布局'
	},
	children: layoutRoutes,
	beforeEnter(to, from, next) {
		let token = getLoginInfo()
		// console.log('token', token)
		if (!token || !/\S/.test(token)) {
			clearLoginInfo()
			next({
				name: 'login',
			})
		}
		console.log('这个是', store.state.common.menuList)
		const {
			menuList
		} = store.state.common
		if (menuList && menuList.length == 1 && menuList[0].list && menuList[0].list.length == 1) {
			next({
				name: 'sentry'
			})
		}
		next()
	}
}, {
	path: '/redirect',
	component: layout,
	hidden: true,
	children: [{
		path: '/redirect/:path(.*)',
		component: () => import('@/layout/redirect'),
		name: 'redirect'
	}]
}, ...globalRoutes]

const router = new VueRouter({
	routes,
	// mode: 'history',
	isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
})

router.beforeEach((to, from, next) => {
	//这这里开启加载条
	// NProgress.start()
	let token = getLoginInfo()
	console.log(!router.options.isAddDynamicMenuRoutes)
	if (to.path == "/login") {
		next()
	} else if (!token) {
		next({
			name: 'login',
			params: {
				redirect: from.fullPath
			}
		})
	} else if (!router.options.isAddDynamicMenuRoutes) {
		getNav().then(data => {
			router.options.isAddDynamicMenuRoutes = true
			store.commit("common/updateMenuList", data);
			fnAddDynamicMenuRoutes(data)
			next({
				...to,
				replace: true
			})
		}).catch(e => {
			console.log(`%c 菜单列表和权限获取失败，跳转至登录页！！`, 'color:blue', e)
			next()
		})
	} else {
		next()
	}
})
router.afterEach(() => {
	// 在即将进入新的页面组件前，关闭掉进度条
	// NProgress.done()
})

const fnAddDynamicMenuRoutes = (menuList = [], routes = []) => {
	var temp = []
	menuList.forEach(item => {
		if (item.list && item.list.length >= 1 && !item.btnList) {
			// item.list.map(o => {
			// 	o.parentName = item.name
			// })
			temp = temp.concat(item.list)
		} else if (item.url && /\S/.test(item.url) && item.type < 2) {
			item.url = item.url.replace(/^\//, '')
			try {
				var route = {
					path: item.url,
					name: item.url,
					component: () => import(`@/views/${item.url}.vue`) || null,
					meta: {
						menuId: item.menuId,
						parentId: item.parentId,
						title: item.name,
						permissions: (item.btnList || []).map(k => k.perms),
					}
				}

				if (!route['component']) {
					console.log(item.url, 898888)
				}
			} catch (e) {
				console.log(e, 898888)
			}
			routes.push(route)
		}
	})
	if (temp.length >= 1) {
		fnAddDynamicMenuRoutes(temp, routes)
	} else {
		routes.forEach(item => {
			router.addRoute("layout", item)
		})
		router.addRoute("layout", {
			path: '*',
			redirect: '/404',
		})
	}
}

export default router