<template>
	<el-dialog v-dialogDrag v-bind="elDialogBind" title="修改密码" :visible.sync="visible" :close-on-click-modal="false"
		:append-to-body="true" width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataSub()" label-width="80px">
			<el-form-item label="账号">
				<span>{{ userName }}</span>
			</el-form-item>
			<el-form-item label="原密码" prop="password">
				<el-input type="password" v-model="dataForm.password"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="newPassword">
				<el-input type="password" v-model="dataForm.newPassword"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="confirmPassword">
				<el-input type="password" v-model="dataForm.confirmPassword"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button v-noMoreClick @click="visible = false">取消</el-button>
			<el-button v-noMoreClick type="primary" @click="dataSub()">确定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		clearLoginInfo
	} from '@/utils'
	export default {
		data() {
			var validateConfirmPassword = (rule, value, callback) => {
				if (this.dataForm.newPassword !== value) {
					callback(new Error('确认密码与新密码不一致'))
				} else {
					callback()
				}
			}
			return {
				visible: false,
				dataForm: {
					password: '',
					newPassword: '',
					confirmPassword: ''
				},
				dataRule: {
					password: [{
						required: true,
						message: '原密码不能为空',
						trigger: 'blur'
					}],
					newPassword: [{
						required: true,
						message: '新密码不能为空',
						trigger: 'blur'
					}],
					confirmPassword: [{
							required: true,
							message: '确认密码不能为空',
							trigger: 'blur'
						},
						{
							validator: validateConfirmPassword,
							trigger: 'blur'
						}
					]
				}
			}
		},
		computed: {
			userName: {
				get() {
					return this.$store.state.user.name;
				},
			},
		},
		methods: {
			// 初始化
			init() {
				this.visible = true
				this.$nextTick(() => {
					this.$refs['dataForm'].resetFields()
				})
			},
			// 表单提交
			dataSub() {
				this.$refs['dataForm'].validate((valid) => {
					if (valid) {
						this.$http({
							url: this.$http.adornUrl('/admin/sys/user/password'),
							method: 'post',
							data: this.$http.adornData({
								'password': this.dataForm.password,
								'newPassword': this.dataForm.newPassword
							})
						}).then(({
							data
						}) => {
							if (data && data.code === 0) {
								this.$message({
									message: '操作成功',
									type: 'success',
									duration: 1500,
									onClose: () => {
										this.visible = false
										this.$nextTick(() => {
											clearLoginInfo()
											this.$router.replace({
												name: 'login'
											})
										})
									}
								})
							} else {
								this.$message.error(data.msg)
							}
						})
					}
				})
			}
		}
	}
</script>