export default {
	namespaced: true,
	state: {
		parkId: {
			id: 1,
			parkName: ''
		},
		parkInfo: {},
		name: '',
		carPark: 'ruleSet' // 停车规则
	},
	mutations: {
		updateParkId(state, info) {
			state.parkId = info
		},
		updateParkInfo(state, info) {
			state.parkInfo = info
		},
		updateName(state, name) {
			state.name = name
		},
		updateCarPark(state, carPark) {
			state.carPark = carPark
		}
	}
}