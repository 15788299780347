/**
 * Created by sxw at 2023/11/9
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/car/tcartype/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl('/car/tcartype/info/' + id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/car/tcartype/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	console.log(data);
	return http({
		url: http.adornUrl("/car/tcartype/delete/" + data[0]),
		method: "post",
	})
}