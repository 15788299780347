<template>
	<div style="display:inline;">
		<el-popover ref="xw-car-keyboard" placement="bottom-start" trigger="click">
			<div class="xw-car-keyboard">
				<div v-for="(item,index) in list" :key="index" @click="keyboard(item)">
					{{item}}
				</div>
			</div>
		</el-popover>
		<el-input v-popover:xw-car-keyboard v-model.trim="plateNumber" placeholder="请输入车牌号" clearable @input="change"
			@focus="focus" v-bind="$attrs" style="width:100%;"/>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: String,
				default: ''
			},
			validateEvent: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				visible: false,
				plateNumber: '',
				list: [],
				cityList: [
					'京',
					'沪',
					'粤',
					'津',
					'冀',
					'豫',
					'云',
					'辽',
					'黑',
					'湘',
					'皖',
					'鲁',
					'苏',
					'浙',
					'赣',
					'鄂',
					'桂',
					'甘',
					'晋',
					'陕',
					'蒙',
					'吉',
					'闽',
					'贵',
					'渝',
					'川',
					'青',
					'琼',
					'宁',
					'藏',
					'新',
				],
				engList: [
					1,
					2,
					3,
					4,
					5,
					6,
					7,
					8,
					9,
					0,
					'Q',
					'W',
					'E',
					'R',
					'T',
					'Y',
					'U',
					'O',
					'P',
					'A',
					'S',
					'D',
					'F',
					'G',
					'H',
					'J',
					'K',
					'L',
					'Z',
					'X',
					'C',
					'V',
					'B',
					'N',
					'M',
					'港',
					'澳',
					'学',
					'领',
					'警',
					'军'
				]
			}
		},
		watch: {
			value() {
				this.plateNumber = this.value
			}
		},
		methods: {
			keyboard(item) {
				this.plateNumber += item
				this.change()
			},
			focus() {
				const len = this.plateNumber.length
				if (!len) {
					this.list = this.cityList
				} else {
					this.list = this.engList
				}
			},
			change() {
				const value = this.plateNumber
				console.log(this.$parent, this.validateEvent)
				this.$emit('input', value)
				this.focus()
				if (this.validateEvent) {
					this.$parent.$emit('el.form.blur', [value])
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.xw-car-keyboard {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		max-width: 360px;

		>div {
			width: 30px;
			height: 30px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			margin: 5px;


			&:hover {
				color: #fff;
				background: #007aff;
				border-radius: 10px;
			}

			&:active {
				// border: 1px solid #007aff;
				border-radius: 10px;
				background: rgba(#007aff, 0.6);
			}
		}
	}
</style>