<template>
	<div :class="'el-select el-select--'+size">
		<div :class="'el-input el-input--suffix el-input--'+size">
			<input disabled readonly autocomplete="off" placeholder="请选择" class="el-input__inner" :value="value">
			<span class="el-input__suffix">
				<span class="el-input__suffix-inner">
					<i class="el-select__caret el-input__icon el-icon-arrow-up"></i>
				</span>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['value'],
		data() {
			return {
				size: sessionStorage.getItem('ElementSize') || 'mini',

			}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style>
</style>