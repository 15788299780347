/**
 * Created by sxw at 2024/06/12
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/park/area/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function listAll(data) {
	return http({
		url: http.adornUrl("/park/area/listAll"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl('/park/area/info/' + id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/park/area/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	return http({
		url: http.adornUrl("/park/area/delete"),
		method: "post",
		data: http.adornData(data, false),
	})
}

export function getCarTypeRuleList(data) {
	return http({
		url: http.adornUrl("/park/tparkrule/getCarTypeRuleList"),
		method: "post",
		data: http.adornData(data),
	})
}
export function getCarTypeRuleSave(data) {
	return http({
		url: http.adornUrl("/park/tparkrulearea/save"),
		method: "post",
		data: http.adornData(data),
	})
}